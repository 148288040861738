<template>
  <div v-if="badges.length" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 flex items-center">
    <p class="text-sm text-gray-700 mb-1 mr-2">{{ $t("filters.filters") }}:</p>
    <div>
      <button v-for="badge in badges" :key="badge.key" @click="removeFilter(badge.key)" type="button" class="mr-2 mb-1 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-ifgreen hover:bg-gray-50">
        {{ badge.label }}: {{ badge.value }}
        <XMarkIcon class="h-4 w-4 ml-1" aria-hidden="true" />
      </button>
      <button @click="clear()" type="button" class="mr-2 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-ifgreen hover:bg-gray-50">
        {{ $t("filters.clear") }}
      </button>  
    </div>
  </div>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{ $t("filters.searchInstallations") }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div v-for="filter in filters" :key="filter.section" class="mb-4 -mx-1 flex flex-wrap">
                      <h3 class="mb-2 text-gray-700 font-bold px-1 w-full">{{ filter.section }}</h3>
                      <div v-for="field in filter.fields" :key="field.name" class="mb-1 px-1" :class="`w-${field.width}`">
                        <label class="block text-sm font-medium text-gray-700">{{ field.label }}</label>
                        <input v-if="['string', 'date'].includes(field.type)" v-model="value[field.name]" :type="field.type == 'date' ? field.type : 'text'" class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-ifgreen focus:border-ifgreen sm:text-sm" @keyup.enter="apply" />
                        <select v-if="field.type === 'list'" v-model="value[field.name]" class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-ifgreen focus:border-ifgreen sm:text-sm">
                          <option :value="null">{{ $t("filters.all") }}</option>
                          <option v-for="option in field.choices" :key="option.value" :value="option.value">{{ option.label || ((option.values && option.values[$i18n.locale]) || option.value)}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button @click="clear" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ $t("filters.clear") }}
                  </button>
                  <button @click="apply" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{ $t("filters.filter") }}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { mapState } from 'vuex'

export default {

    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      XMarkIcon,
    },
    emits: [],
    props: {
      admin: {
        type: Boolean,
        default: true
      },
      entity: {
        type: String,
        default: 'installations'
      },
      showInstallerFields: {
        type: Boolean,
        default: true
      },
      showServicesFields: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        open: false,
        value: {
          "customDevice.deviceTemplate.id": null,
        },
        badges: []
      }
    },
    mounted () {
      console.log(this.deviceTemplates)
    },
    computed: {
      ...mapState(['deviceTemplates']),
      paramFields () {
        console.log(this.value["customDevice.deviceTemplate.id"])
        if (this.value["customDevice.deviceTemplate.id"] === null) {
          return []
        } else {
          const template = this.deviceTemplates.find(template => template.id === this.value["customDevice.deviceTemplate.id"])
          return template.activeVersion.parametersSchema
        }      
      },
      filters () {
        const installationPrefix = this.entity == 'services' ? 'customDevice.installation.' : ''

        return [
          { 
            section: this.$t("filters.installationDataHeader"),
            show: true,
            fields: [
              { name: `${installationPrefix}installationDate[after]`, type: "date", label: this.$t("filters.installationDateAfter"), value: "", width: '1/2' },
              { name: `${installationPrefix}installationDate[before]`, type: "date", label: this.$t("filters.installationDateBefore"), value: "", width: '1/2' },
              { name: `${installationPrefix}customerName`, type: "string", label: this.$t("filters.customerName"), value: "", width: '1/2' },
              { name: `${installationPrefix}customerAddress`, type: "string", label: this.$t("filters.customerAddress"), value: "" , width: '1/2'},
              { name: `${installationPrefix}customerCity`, type: "string", label: this.$t("filters.customerCity"), value: "", width: '1/2' },
              { name: `${installationPrefix}customerZipcode`, type: "string", label: this.$t("filters.customerZipcode"), value: "", width: '1/2' },
              { name: `${installationPrefix}customerEmail`, type: "string", label: this.$t("filters.customerEmail"), value: "", width: '1/2' },
              { name: `${installationPrefix}customerPhone`, type: "string", label: this.$t("filters.customerPhone"), value: "", width: '1/2' },
            ]
          },
          { 
            section: this.$t("filters.installerDataHeader"),
            show: this.showInstallerFields,
            fields: [
              { name: "installer.name", type: "string", label: this.$t("filters.installerName"), value: "", width: '1/2' },
              { name: "installer.company", type: "string", label: this.$t("filters.installerCompany"), value: "", width: '1/2' },
              { name: "installerEmail", type: "string", label: this.$t("filters.installerEmail"), value: "", width: '1/2' },
              { name: "installerPhone", type: "string", label: this.$t("filters.installerPhone"), value: "", width: '1/2' },
            ]
          },
          { 
            section: this.$t("filters.serviceDataHeader"),
            show: this.showServicesFields,
            fields: [
              { name: "serviceStartDate[after]", type: "date", label: this.$t("filters.serviceStartDateAfter"), value: "", width: '1/2' },
              { name: "serviceStartDate[before]", type: "date", label: this.$t("filters.serviceStartDateBefore"), value: "", width: '1/2' },
              { name: "serviceEndDate[after]", type: "date", label: this.$t("filters.serviceEndDateAfter"), value: "", width: '1/2' },
              { name: "serviceEndDate[before]", type: "date", label: this.$t("filters.serviceEndDateBefore"), value: "", width: '1/2' },
              { name: "sparePartsSentDate[after]", type: "date", label: this.$t("filters.sparePartsSentDateAfter"), value: "", width: '1/2' },
              { name: "sparePartsSentDate[before]", type: "date", label: this.$t("filters.sparePartsSentDateBefore"), value: "", width: '1/2' },
            ]
          },
          { 
            section: this.$t("filters.deviceDataHeader"),
            show: true,
            fields: [
              { 
                name: "customDevice.deviceTemplate.id", 
                type: "list", 
                label: this.$t("filters.deviceType"),
                choices: this.deviceTemplates.map(template => {return {value: template.id, label: (template.nameTranslations && template.nameTranslations[this.$i18n.locale]) || template.name}}), 
                width: 'full' 
              },
              ...this.paramFields.map(field => {return {...field, label: (field.labels && field.labels[this.$i18n.locale]) || field.label, name: `param.${field.name}`, width: '1/2'}})
            ]
          }
        ].filter(filter => filter.show)
      },
      filtersFlat () {
        return this.filters.flatMap(filter => filter.fields)
      }
    },
    methods: {
      show () {
        this.open = true
      },
      async apply () {
        this.badges = this.createBadges()

        this.$store.dispatch('setLoading', true)

        const searchParams = new URLSearchParams()
        for (const [key, value] of Object.entries(this.value)) {
          if (value) {
            if (key.startsWith('param.')) {
              const name = key.split('.')[1]
              searchParams.append(`parameter[${name}]`, value)
            } else {
              searchParams.append(key, value)
            }
          }
        }

        console.log(searchParams.toString())
        const query = searchParams.toString() ? `&${searchParams.toString()}` : ''
        if (this.entity == 'installations') {
          if (this.admin) {
            await this.$store.dispatch('getCollection', {entity: 'installations', status: 'accepted', page: 1, query})
            await this.$store.dispatch('getCollection', {entity: 'installations', status: 'pending', page: 1, query})
            await this.$store.dispatch('getCollection', {entity: 'installations', status: 'rejected', page: 1, query})
            await this.$store.dispatch('getCollection', {entity: 'installations', status: 'no_warranty', page: 1, query})
          } else {
            await this.$store.dispatch('getCollection', {entity: 'installations', page: 1, query})
          }
        } else {
          await this.$store.dispatch('getCollection', {entity: 'services', status: 'done', page: 1, query})
          await this.$store.dispatch('getCollection', {entity: 'services', status: 'rejected', page: 1, query})
          await this.$store.dispatch('getCollection', {entity: 'services', status: 'require_spare_parts,spare_parts_sent', key: 'pending', page: 1, query})
        }
        this.$store.dispatch('setLoading', false)

        this.$nextTick(() => this.open = false) 
      },
      removeFilter (key) {
        this.value[key] = null
        this.apply()
      },
      clear () {
        this.value = {"customDevice.deviceTemplate.id": null}
        this.apply()
      },
      createBadges() {
        const badges = Object.entries(this.value)
          .filter(entry => entry[1])
          .map(([key, value]) => {
            const filter = this.filtersFlat.find(filter => filter.name === key)
            if (key == "customDevice.deviceTemplate.id") {
              const template = this.deviceTemplates.find(template => template.id === value)
              value = (template.nameTranslations && template.nameTranslations[this.$i18n.locale]) || template.name
            }
            if (key.startsWith('param.')) {
              const name = key.split('.')[1]
              const field = this.paramFields.find(field => field.name === name)
              if (field.type === 'list') {
                const choice = field.choices.find(choice => choice.value === value)
                value = (choice.values && choice.values[this.$i18n.locale]) || choice.value
              } 
            }
            return { key, value, label: filter.label }
          })
        return badges
      }
    },
    watch: {
      value: {
        handler (value) {
          console.log(value)
        },
        deep: true
      },
    }
}
</script>
